import React from 'react';

function ContentSection({ id, title, content }) {
  return (
    <section id={id} className="content-section">
    <section id="content" className="wide-content">
      <div className="row">
      <br />
        <div className="embed-container">
          <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/Ht25EMZVEuQ?autoplay=1"
            frameBorder="0"
            allow="autoplay; fullscreen"
            title="YouTube Video"
          ></iframe>
        </div>
      </div>
    </section>
    </section>
  );
}

export default ContentSection;
