import React from 'react';
import logo from '../images/The_Glass_Octopus_Logo_340w.png'; 


function Header() {
  return (
    <header>
      <br />
      <img src={logo} alt="Logo" />
      <br /><br />
    </header>
  );
}

export default Header;
