import React from 'react';
import SubmissiveEP from '../images/CoverArt_Submissive Masses_450px.png';

function HeroSection() {
  return (
    <section id="home" className="hero">
      <div class="hero">

      <div class='banner'>
        <  div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <h1>Out Now! Submissive Masses EP</h1>
                <img src={SubmissiveEP} alt="Submissive Masses EP" className="img-fluid" />
                <br /><br />
                <a href="https://www.example.com" target="_blank" rel="noopener noreferrer">
                <button className="btn-red">Buy MP3 Download - $2.99</button>
                </a>
                <a href="https://www.example.com" target="_blank" rel="noopener noreferrer">
                <button className="btn-red">Buy on iTunes - $2.99</button>
                </a>
                <a href="https://www.example.com" target="_blank" rel="noopener noreferrer">
                <button className="btn-red">Buy on Amazon - $2.99</button>
                </a>
              </div>

            </div>
          </div> {/* end banner div */}
        </div> {/* end container div */}


      </div> {/* end hero div */}
    </section>
  );
}

export default HeroSection;