import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS here
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ContentSection from './components/ContentSection';
import Footer from './components/Footer';
import ReactGA from 'react-ga4'; // Import Google Analytics

const TRACKING_ID = "G-57K7C33Q0Z"; // Replace with your actual Tracking ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.send("pageview"); // Send pageview on app load
  }, []);

  return (
    <div className="App">
      <div className='background'>
        <Header />
        <HeroSection />
        <br />
        <ContentSection />
        <br />   
      </div>             
      <Footer />
    </div>
  );
}

export default App;
